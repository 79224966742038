import React, { useState } from 'react';
import PropTypes from 'prop-types';
import "./PasswordProtection.css"; 

const PasswordProtection = ({ children }) => {
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === '@ntaSecure!') {
      setIsAuthenticated(true);
    } else {
      alert('Mot de passe incorrect');
    }
  };

  if (!isAuthenticated) {
    return (
        <div className="protected-container">
        <div className="protected-form">
          <h1>Accès protégé</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Entrez le mot de passe"
            />
            <button type="submit">Se connecter</button>
          </form>
        </div>
      </div>
    );
  }

  return children;
};

PasswordProtection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PasswordProtection;